.badge-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.badge-item {
  padding: 10px 15px;
  border: 2px dashed #000; /* Broken line border */
  border-radius: 5px;
  display: inline-block;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: bold;
}
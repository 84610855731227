.cell-template.e-schedule .e-month-view .e-work-cells {
    position: relative;
  }
  
  .cell-template.e-schedule .templatewrap {
    text-align: center;
    position: absolute;
    width: 100%;
  }
  
  .cell-template.e-schedule .templatewrap img {
    width: 30px;
    height: 30px;
  }
  
  .cell-template.e-schedule .caption {
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }

  .e-schedule .e-content .e-work-cells[data-date='2021-08-02'] {
    background-color: red;
    color: white;
  }

  .custom-input {
    max-width: 32px;
    min-width: 32px;
  }

  .tabledata{
    min-width: 50px;
  }

  .tabledataTitle{
    min-width: 135px;
  }

  .custom-table{
    margin: 16px;
  }









  .custom-rows {
    margin-top: 10px;
    max-width: 88vw;
    min-height: 82vh;
    overflow-x: auto;
  }
  
  .custom-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .tabledataTitle {
    background-color: #cccccc61;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
  }

  .configTitle {
    background-color: #cccccc61;
    text-align: center;
    padding: 10px;
    border: 1px solid #ddd;
    font-size: 14px;
    font-weight: 400;
  }

  .tableDateView {
    background-color: #cccccc61;
    border: 1px solid #ddd;
  }
  
  .tabledata {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }

  .configCellData {
    border: 1px solid #ddd;
    /* padding: 10px; */
    text-align: center;
  }

  .configCellData:hover {
    background-color: #dddddd9f;
    cursor: pointer;
  }
  
  .room-type {
    background-color: #f8f8f8cb;
    color: #4eada7;
    font-size: 14px;
  }
  


  .customDayFormat{
    display: block; /* Display on a new line */
    font-size: 14px; /* Adjust font size as needed */
    margin-top: 4px;
    text-align: center;
    font-weight: 400;
  }

  .activeStatus{
    background-color: #76bd33;
    cursor: pointer;
  }

  .openStatus{
    cursor: pointer;
  }

  .closedStatus{
    background-color: #f13708;
    cursor: pointer;
  }

  .semiClosedStatus{
    background-color: #f1e108;
    cursor: pointer;
  }

  .closedStatusBlocked{
    background-color: #f13708;
    cursor: not-allowed;
  }




  


  .reservations {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: 50px; /* Adjust as needed */
    position: relative;
    z-index: 1;
  }
  
  .reservation-item {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
  }
  










  
.schedule-drag-drop .specialist-image {
  width: 45px;
  height: 40px;
  float: left;
  border-radius: 50%;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 45px;
}

.e-device-hover {
  background-color: #e0e0e0 !important;
}

schedule-drag-drop .e-resource-cells.e-parent-node .specialist-image {
  display: none;
}

.template-wrap .specialist-image.roomImage {
  background-image: url('https://ej2.syncfusion.com/react/demos/src/schedule/images/alice.png');
  display: none;
}
@import './colors.css';

.slotManager_row {
  padding-top: 1rem;
}

.addeditslot_row {
  margin-top: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.isRotated svg {
  transform: rotate(-90deg);
}

.checkbox_row {
  padding-bottom: 0.5rem;
}

.danger {
  color: rgb(255, 77, 79);
}

.action_button {
  font-size: 0.7rem;
}

.info_button {
  font-size: 0.7rem;
  width: 2rem;
}

.slot_tableHeader {
  font-size: 0.9rem;
}

.slot_tablebody {
  font-size: 0.8rem;
}

.select_message {
  margin-top: 1rem;
  margin-left: 1rem;
}

.slot_manager_header.modal-header {
  border-bottom: 0 !important;
  padding-left: 2rem;
  padding-top: 1rem;
}

.slot_manager_footer.modal-footer {
  border-top: 0;
}

.slot_manager_body {
  padding-top: 1rem;
  padding-left: 2rem;
}

.slot_manager_radio {
  padding-bottom: 1rem;
}

.slot_manager_modal {
  width: 400px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

.form-check-label {
  padding-left: 0.5rem;
}

.edit_header_icon_save {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.5rem;
  color: var(--primary--color)
}

.edit_header_icon_close {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey
}

.accordion_card {
  margin-bottom: 0px;
}

.slot_table_date {
  margin-bottom: 1rem;
}

.selected_slot_info {
  margin-bottom: 1rem;
}

.manual_icon_slot_manager {
  float: right;
  font-size: 1rem;
  color: var(--primary--color);
  cursor: pointer;
  margin-top: 6px;
}

.test_switch {
  max-height: 10px;
}

.bed_input {
  padding-top: 25px;
}

.modal-70w {
  max-width: 70% !important;
}

.modal-80w {
  max-width: 80% !important;
}
.modal-90w {
  max-width: 90% !important;
}

.modal-60w {
  max-width: 60% !important;
}

.modal-50w {
  max-width: 50% !important;
}

.ow_hotel_booking_bedIcons {
  font-size: 5rem !important;
  color: #5F6B7C;
}

.ow_room_booking_bedIcons {
  font-size: 3rem !important;
  color: #5F6B7C;
}

.ow_room_booking_bedNames {
  font-weight: bold;
  margin-bottom: 0rem !important;
  font-size: 0.9rem;
}

.ow_room_booking_sizeNames {
  font-size: 0.8rem;
}

.ow_room_booking_ColumnButton {
  background-color: transparent;
  color: black;
  border-color: black;
}

.ow_room_booking_amountTitle {
  margin-right: 0.5rem;
}

.ow_room_booking_saveIcon {
  cursor: pointer;
  margin-right: 15px;
  font-size: 1.5rem;
  color: var(--primary--color)
}

.ow_room_booking_closeIcon {
  cursor: pointer;
  font-size: 1.5rem;
  color: grey
}

.ow_room_booking_downloadIcon {
  cursor: pointer;
  color: var(--primary--color);
  font-size: 20px;
}

.ow_room_booking_modalWidth {
  max-width: 80%;
}

.ow_room_booking_exportCard {
  font-size: 0.7rem;
}

.ow_room_booking_mtr {
  padding-top: 1rem;
}

.buttons {
  display: flex;
  justify-content: center;
}

.buttons__button {
  width: 180px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #003487;
  margin: 5px 12px;
}

.buttons__button:hover {
  cursor: pointer;
  box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
}

.buttons__button--next {
  background-color: #003487;
  color: #fff
}

.buttons__button--back {
  background-color: #fff;
  color: #003487
}

.slot_recurrence {
  padding-top: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.custom-dialog {
  max-width: 60%;
}

.ow_room_booking_breakfast {
  margin-top: 24px;
}

.ow_room_booking_amenityIcon {
  color: var(--approved--color);
  font-size: 0.7rem;
}

.ow_room_booking_amenityText {
  font-size: 0.8rem;
}

.ow_room_booking_configData {
  margin-bottom: 0px !important;
  font-size: 0.9rem;
}

.ow_room_booking_cribInput {
  width: 5rem;
}

.ow_room_booking_detailsCheck {
  margin-top: 1.5rem;
}

.ow_room_booking_totalGuest {
  padding-top: 1.2rem;
}

.ow_room_booking_bedTypeIcon {
  font-size: 1rem !important;
}

.ow_room_booking_bedTypeText {
  font-size: 0.8rem;
}